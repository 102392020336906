import Layout from '../components/layout'
import React from 'react'
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap'
import Recaptcha from 'react-recaptcha';

export default function ContactMe() {
  return (
    <Layout>
      <Container className="mt-2">
        <h1 className="text-center">Get in touch with me</h1>
        <Form method="post" action="https://getform.io/f/f9a12a99-0b8b-47a9-9684-177bdacf538d" className="mx-auto" style={{ maxWidth: '30rem' }}>
          <FormGroup>
            <Label labelFor="#email">
              Email
            </Label>
            <Input id="email" type="email" name="email" required />
          </FormGroup>
          <FormGroup>
            <Label labelFor="#name">
              Name
            </Label>
            <Input id="name" type="text" name="name" required />
          </FormGroup>
          <FormGroup>
            <Label labelFor="#message">
              Message
            </Label>
            <Input id="message" type="textarea" name="message" required />
          </FormGroup>
          <Recaptcha sitekey="6LdnXQEVAAAAAJsA-5nS1yEkven91mWumBeUZQoW" />
          <Button className="mt-2" type="submit">Submit</Button>
        </Form>
      </Container>
    </Layout>
  )
}
